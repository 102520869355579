export const environment = {
  production: true,
  isLive: false,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
     etsy: {
      name: "Etsy",
      API_ENDPOINT: "https://testing-jovy-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM5OWJmNGEwY2JiZDg4N2RlMDQzMjU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQyYThlOGU3Y2YzNDFkYzE0MGZhNWQyIn0.VzA9PBGpW3elBJIy2z8kPVpM66hca_8TaJimQBGzXzilYRcP1s2ZTP7Umqxb7-YLyKqwZwLJSw_Sx_7bDcLSEnJWlU9T-CUb9VK96h2FaH9DY7c6vvaGamZ50kpEjQ6i-sv3L6Lv36GMyZN37YIm9QjytfRSVu1qUMUO4Z8tQYItueWWzTkvv18Blwu8efwGzDkhjxsnrzP1CUXMOJBetc4jUTsv08S1bJSdMOcNPJ2bdy-Zb5oDfAqzLyA_SU04jNjxfy7Tqa7GIDcZqr9fqHvljQ2ev0cv9OeSbOXrMtqqRyldS1v11Dpt7hRLCXaVVB9GSJaeg2J6PGCdS5YHZA",
    },
    miravia: {
      name: "Miravia",
      API_ENDPOINT: "https://testing-miravia-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM5ZDU2YmVkZTdiOWE5ZmRkMGE2YmI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjNhMDI5NDkzYjBhMjI1ZjRhMDk3ZjkyIn0.mP7UC8ytVe1HADK8FxHADHDqfUAS4HdSIhZD9GsuFR6Y_Hcm6oTuh_jqEau-tCkvp6U14n0FLUWTFigFzkmX7Lbjjii0DKv0n2EYct2MIXIuw-W6UsjQQ5vJpjVDyZmxmvT2n0UXr33E3TUeZZC8ubyzLuF6jQn8S6rhqJFNyi5rT0QoIFYGyzbsPdNerUuiZE0-JHoDDXi_Ou4aN6QgNpBH24Wgu22t3tF05hhrlbd8g5Q7OCFPqdKa9JIOYF8_1eTw53fUuzUpjW9cEsUsDNlu2bm0N3_ChOEjW4J1kjPkI6wdsouBXVQcHgbte9NrtKui5_2YmtGKhRm8xuGUpg",
    },
    zoho: {
      name: "Zoho",
      API_ENDPOINT: "https://testing-zoho-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjNhMDE3MzA5MDg2NjQ4NTlmMGM0Y2Y3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjNhMDE3NzM5Y2Y0N2Q3NjZiMDc1OWUyIn0.psR2RN-7YTP0E1rBApwOXv0P0gMfIbS6KOz_5uzgg-Hn3CRFsPvBL75whkBmsLOvcMHqDVZFPSBX46_PJfyY55-B2B91xOKwJK754ruYTW0xgvvKaZlT2H1POwM2Bmf97ZXNT-zXjZhSTdEl8anJsW0rDhWXVcgWI_c5WVboh9tPQYIsvWAkdrSViozA_cDQyY6GjJU-K2VFfi75Xl0xowW2mbU-H1JOxqd0Jw4ISGJCP5F5P3zJroAl25wqty0GSUzeukf9IUTJxPrf0MPoF6RlpzMWitUsak40u4xPBDG1JMplxGU68G3hPZKt2D7ML9PwtBCoycEZPXSWbVcV9A",
    },
    hubspot: {
      name: "Shopline",
      API_ENDPOINT: "https://testing-hubspot-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjJkOGZiMDJhMzc5OGYxNWMyMGNiNjg3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjJmMzNiNGNiOTEyOTdiMDE3MGY4YTcyIn0.JYUAiGffI2zO1S6uROk_jLHXxmFecxq6e9DLPYk2iDzhwfLeXn9U5dq3YyIiFwAmfnZTfNskM8AACrPqoUzfJVsO_ENZFR4HTSnGtT6eTC_MGWhZujaPg2mX9OJ_KO9Nl4aHlw-cKPjPPFFHbtmPaJSNcOOWOB63eUQ-GKxO44l784eIgvP5s2lgt4oM6MzLEeUTp0hSb7WuGxwOe4zrk48Ogwzk5qbbzRmwuqUUCeJl4Nove_IHn7jts8Rx36uUOBHQ631cIMdEak5adaKTdNLkK17ifaX5v7FZLnhk2I3Dw13jPvFo5HJLX2sd84SWZ6PJNi3g-caCxFMj37NdhQ",
    },
    shopline_catch: {
      name: "Shopline-Catch",
      API_ENDPOINT: "https://shopline-catch-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQxMWE1MjdiODFmMWI0Y2VhMGE3OTM3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQxMWE1NDA4OGI2MDRkNmQwMDQxYWIyIn0.VZoAsPG4d2kvoGYzvCR72IH9EumfRFnkfUoWVkVLLBwqJpkXV3uX4cesxkaBKk8AwD0scjLQQeiyHreszTV47gMbXOpP6ThgDqbIUjahjsMBVmsF4JMl7zCgTBfGWu7X8h7RsoLwqubZIy5nvYzUi0vQBiblC1D9WZrknzqc4UWyxuqBsr1ZkC1OzQNe4daSEjpayB_FJwESM4LdMoUHUyIa0Y0vdxdrohUXo4gjLclJX_NDCrgJF1PHew9eB30AJKU1Kp77ns9gFX8D6copmzCVBb2FHnyZbbcUNbDhHB1xieWK0n6RjwHsWzI2ykFK-8jrpwjMe-WTc-FywuUCQw",
    },
    shopline_amazon: {
      name: "Shopline-Amazon",
      API_ENDPOINT: "https://shopline-amazon-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQxMTllMzc1ZmU1MDg3YjhiMGY3Mjk3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQxMTllYTM5ZTBiYjcyZDEzMDk4YjkyIn0.UgWKpxxgz_ECjb8WTsbtWgdE5Pw5QLFI9AkL9fUe9r-HX7pl0ES6PXVD27QlRWeQaHn7-N_y4SCTAMy5S5QPepaC1yXQnKbGK3H9pa8MAKoWDmVfB3BzIK71aP7p8W_OH6nra3UVJ8yPj5PF4Io5rlkaC7EUbZHJlOgv3B7k0yuTaJnN95gIfO6LWRDijtM3yaQ4NnpfPhOFIMfyjwMY78O3O5zO3HAqqFwE5g2V4iGkOYou8zuBhjV5H2nj4X9WDdc6_ywzAOMe3PMjkbV6hbR83cK-Xnwvz_J4u1R0MIu4h47xU8p--MxorgAOnZJhXtEjYXDqr03KDAs2aFaEPg",
    },
    shopline_ebay: {
      name: "Shopline-Ebay",
      API_ENDPOINT: "https://shopline-ebay-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQxMWJjMjk0ZjVmZTBkZmI0MGEyMzg3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQxMWJjNDdlNjc3NWQyYzAyMGM4YWIyIn0.Wp2kp2slzyPEB3RNeBOWKNX7yOqVUSxbnipHoO7KEcEehAlBZVET93hx7jhhl3UC20uDtPlb2IeK4NSZwaDeDv8Ml61jlkg-R-QgMOo2GU2TIn5AR_wnFRE35_JVg3x6EgHcwUhICcopdjmJ8PKlpgXQSmNybmJN9x6qeTqUIJ_4tqUN62iob-n4Ci7M6Bj4YdcZGP4NL4cnZx01JQB5KTeN1tgjyIplvbcAZQcUZy2jGE12MqWcr2FKAdkv7-l_5C7l7eD9n_Laj6L9B2esLTwUiBH468ZbS-3WWOB2WIghZ_SrEiDWLuyNBn5RsiZ_59LfFcMkxMO5oVO6wynaMw",
    },
    mirakl: {
      name: "Mirakl",
      API_ENDPOINT: "https://mirakl-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQxMWU3Y2RiNWExMDZjZTMzMGRhZDE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQxNDA3OWY5YmYwMWNkYzBjMGNlZmYyIn0.ho_n8sY9MZIBdC935XU5XItZ30lEjE-xCUO3t12ij-Q9iLMsIjsqmE5s9tCCqRTKFoK4Ju09Xrezkbm_cUZ7cAj6sWAZMXtMYstIDqgEzVlOuUXNAZ8E7MGwES_Ez8z3fqoLY1fcMOvHxGEHMhdWTEh23g9VzJUK2vzMUHOcI7mNlPWNaAQdXuJiauo6ateqNQQvMBq91H233iJTB7uYekLG1-ol4f22Ylqsw0W3HYzNLwTuDemMCDU6itb8zgq6KrbI1qS8Bb6ZeQRk0vgtk9vNgrZVYQ8AFWDuazfwhAL9AH90J2hhNEJSkReqRsl-uBNkqQNFv_DRdXOFlT6R6A",
    },
    manomano: {
      name: "Manomano",
      API_ENDPOINT: "https://manomano-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjQxOTc4NDczNTBhMzU4OTU1MDRjMjQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQxOTc5MTAzZDMwOTkxMDk5MDFjMTYyIn0.Arte0AM-dgopy7FzDE3uBnHxNIx1XkDgUlHhcx-MFU6cZveiXra6O0aV3y_B4vL_UVFgPFpjxeZ4FIQEx4xTgQhp7SMgMyll4hrTZWw6wHVhhx53SfxzRFzzcGFVG0IT0sscOHSWJlhDef-tOA6b5b3H42s0tMUrh4XRgjGA3XzCCABN1q16b7fhEY7m-PyptigC35AnS3hmC6xSiRhbyYyOnM3e7VhkqTatEzPmOF__tBz295qcYAI1rjZSQQGvRrGEL7SXljwPjN3qvLhKi3x3Cpu3Pj2wV_Q0gUjDt1eV_zLw_VS6f8fOAyI1NLB_O3myeKp62di0eXXJL1MWNQ",
    },
    michaels: {
      name: "Michael",
      API_ENDPOINT: "https://connector-dev.cifapps.com/remote-connector/public/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM3YzYzYTljZDIxNzI0MDBiNTQyODI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5YWU1YjI4NGQyZTM1MjMwN2IyNjUyIn0.s0OluSxMp0xYql5NgVW2UevaA476Fso9MmpZD4Zd3Zx5FkMMUL7bPUfjpYCKoLtx_CWHp25PiQy__tjMQoQ9Ku9wCH086U6IWqR4hJj-ZFHuq3c4F2bgyFGcZgg2Vha8nYob9wfMOazNp6Mqn5_PeZU9wfHVROnkAzhtgwb8tomu0AIiZoryFVRQ_DHZovqnA5Yfh9phmwJlCJE6X05Pz9Etbm5UxixpDtCmi10bHEBlwRvZQ8XG9WieUwb5W8eW42ck3tQHvajQYAda2d0ljHhN2B0VpANQgHZhUU3o6vgSXTL8p6lp4aovErclKZIkZmt8rPyO2j74fpzMuLtgWQ",
    },
    tiktok: {
      name: "Tiktok",
      API_ENDPOINT: "https://connector-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjM3YzYzYTljZDIxNzI0MDBiNTQyODI3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjQ5ZmU2ZDI0ZjQyY2IxOWNiNGRmZDUyIn0.IEbMdX1XfHTopV9vgceiHVPnH-51nQuW4ZbQcaGGSSwU5xaPqm7G_RDBrbyYzy5ajWT2Wxh8IGVHmjB1QRrUEjsCWlF_ojdZhf4DDvS2m_aY9VYYKf2LAx5qb96v1gikbhlwKe7oaex7ebhA1iDK6uFx2ABJynTMWU1ODOF4PVMUaRlHvNyQdrOd4o4GKrl7Ayn8UHizjGQTRroGJUSHFwn9kA0yWajBc51E3NLEZqAZBgtgou3KydbWuuz9u-hmOFkYsGkdOQ73VjJVdlMz4It1llmApKUG-y-LXUpX-iqTA2MN3aI8os3Ney5snVZZgzFdYNI9hBUpdqtRawjkjQ",
    },
    mirakl_sfcc: {
      name: "Mirakl-SFCC",
      API_ENDPOINT: "https://mirakl-sfcc-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRhMDE2ZTJmNzg5NTg1OTU3MGU5ZTU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRhMDE4YWY1Y2JmNWM0OWFmMDY1OGUyIn0.tBYsMa41MNzyhugCfAs1Woe1CsgerVbwjwQv1jbxJwtnPpO5qzF1PErLr1qeUnX3YVZ0V8o3W7S-UWUeQ2wJFXRnb4S3iRhOvVXaljVThEiAOHqTcAl8Fgb3NihcFiu7MSRSKWhNYuEVw3U39pNaJq9w9KWIg27H6hwzFUPNW5PvoJPNNV2a9aJe8kslzbpW3-aOs6gHijSIT0tVxh8Wc8RZrvW1k6wqXQfwC-WQEKlxu0TOQBTBfShw9bllRMQdtJheHdGEI7ym-xh8IRjbfOzM8i_XLRHqtEmFI_9obsNjeMFMFNkSxAMy-cd7Z0ZOcG7_72pSWJPHJ6NV3TprCg",
    },
    shein: {
      name: "Shein",
      API_ENDPOINT: "https://shein-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRhZWUyMjYyYzYyMGZkNmMxMDc1YjQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRhZWUyMmJjOGM0MjYyZTBkMDZhY2IyIn0.K1Ang4pcjjkBwfjwqD2FVkRDlxeCCjrA3rcAftm21OO8_98-v-rUaPUgOXSbX0q07rhvbsPV30qJdWv1hwjEhEilGkX5nolyW5VaVQQ6vZ1cSRp9k9RltujP5COcI-4_muGYAW9BDu9rJY4TKUrU8B8K6vLvYdsSji9shO2rlx6TFhhVpFnQa-OBBJ1sk6fH_N09wOu2DNHl1pFMQI7L1e7Kzqlrn1LSQiv8xXAGpUsPTQ366P2kUrk_7omRYe8MN2lTjMTaePTP5UUwjSshvcH8WD-HNYuSD6on-24UJ8q1DJ2xSmjOTx_uNRsddDS1MayXMgxkIcRFaSRXfrz-3Q",
    },
    mcf_shopify: {
      name: "MCF-Shopify-Integration",
      API_ENDPOINT: "https://mcf-shopify-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRiN2E0YjFhNjg4OGZiOTNmMDA0YTU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRiN2E0Yzg1MTA5MTE2ZjE3MDZkYTkyIn0.Q7Rl-rLa_FRJuWz3fPZvNDUADQR71aMm7Qgb0bEimpuYPJHKCIxlAAVetGip28tQwSHeCeqfYV6OGraWfCUbpcE7Lo8P4GEGhEo5rq7yfVQPa2qVsfrjSwfA9USn1UXvTvd-Dcba5dsZCplxJq3d2D9rTcc9ctqd0yepdGCH4CcpEjooPV8mnx_3QeCiojnx_8uz735-xMnrqw4eQalkord5wFDTz99hBdNjWvU4uBtejM1GLWJNUq_1qLbTHbxGjNU9eQZn5T_vuepZ12JFhhoTjiAskSvqGVY30CFVkNlFzdt6J40f-ixAQANiOockvuX-eLQpHQdriot7Whaw5w",
    },
    trendyol_aliexpress: {
      name: "TrendyOl-Aliexpress",
      API_ENDPOINT: "https://trendyol-aliexpress-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRmODljYTA4M2RiMDIzYWRhMGYwMmM3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRmODljZDgwYTdkZjE4ZTY2MGNmOWQyIn0.gb7mwtGJBLeCSawZ0sYrU3ie3fCCCQqkKSxQ-7ExcdMKAwS3k-PcIIXwPCnmSMPkUg-sW4A9lWwVpegck8VoEmOtkL84tsoiGF-luDLiMYLZ1tDqS4XpQ6O9r6w56c5h9pfaRbtCRmQzC3DDhHaFzeIzh5ndYXG7m2QhDPLE5RQrzUa9HdS9tcQlJdU-diMr6euJE1c3B6Eb0plXQvtROQLDhshUD6tCLNF5H08LjWJZruNH16xISGnP6bB-hHUQmmalg94NkdbBxyncSqwnoyCTe-1gCilsG6PjMXVIK21_eXVcGlSfZ_XJJUOdGFO1jdQn2NBplFnxZSMcMbunpw",
    },
    global_payment: {
      name: "Global-Payment",
      API_ENDPOINT: "https://global-payment-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjRmOWExMWVjZDU3MDE4NWZhMGI3NTY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjRmOWExNDEwOWQ4NmNiZmU1MDNlNDEyIn0.V92z1IrIO8uIMpFcnWaYXEJiYfWvMLIiZYoyFvSyLCYjpj846PTqoQa7UOwewxlpeFTA9tRZuU-ymXLmz_ILn55Ziq6yLBNDS54ZKuSyPUJhmyqu6vBPF0XTTJ31SQc5I--Zh8CDV9WTTpeviGzMJ-AYPyy3V7qwa9fbLC4uXRmVGq_5cRlO936CgjTX9FFlWZU6VrCv5Q7YXe3bRTOxWSrs8eWM33_jAUEIwDVLERglnnW84LI2HTgB1SRl_Lnf51yJN15GiBuO6Ovx1uxNw7NhCWKYjxIpRs34pHWReNrDqneScp0NeCjLNdGy6_v6x74aqgE3iNn4PlzOQxfN-w",
    },
    mcf_shopify_staging: {
      name: "MCF-Shopify-Integration-Staging",
      API_ENDPOINT: "https://one-shipment-stag-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjU1YzY5YzRiYmE5MzcyNzcxMDVjYWU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjU1YzcxMzI3YmRlODIzYzZiMDZjNTgyIn0.sfdqJp0NXhaujIIdYc_meq69-B8urJfFQCurg2lWjtszihavblTreiKSB4v8T1B-KbLjCacoEuSUCDJ4RDO2r-vvb7r1s8mbktWDSNqrDkL-y0QtsyVQHJrWCC2MYNe5gVgICQa8txdSPG94Dr5zmh-_KAwpFB197stSA4oFfO2kXbNOiYXlDQYaaJdEXanPdxXciiWPa85J8RoLpqB0eP5zbnpep4zy7kcgbw0VFvbHbXmLnY4Y58aDfvJJDGPLNabYFNHOlozd63Z2BHyWdf6ZxMR0N0NGqz7KxhfIQPw3heD7U15kMYoAtNV-LivPL0KhGepe43UVUVZKzEX1SA",
    },
    mirakl_mcf_staging: {
      name: "Mirakl-MCF-Staging",
      API_ENDPOINT: "https://mirakl-staging-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjU1ZjkxNGNjMjQ2ODU3NWNlMDUzYzQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjU1ZjkxNTA3OTk1OWQ0M2RhMDkxYjEyIn0.TSm3KfGgzb8MR7_mcRI0ZL_4fbzJkmropY0rZKm66Zx32NcVrhHliHmkNWedClMkqccyhTfDLb9_SWX-rUM0nAe4WinQoWeJb2WtL1TEWMSVyJS4u7e01psN5kq1v7dz20WeqePvhbwO0-k76eGShvf-Lp6GN6YiJCD_gf32Ywns2XczzMtFlBxtJJgs6qS-qWTB6B_ljP1SbS8AT3SCF0Mcpz4Q__ZXvhIjdkD1YRscduf0XXqvl5A70ZiPt1P73anCxF92A5GzOZtL_hIru0-S8b9h2k64FENdCav3seaE7FCYmWT4LWQV697NXCRlH6HOJnrFwClliycU2H1MOQ",
    },
    mirakl_woocommerce: {
      name: "Mirakl-Woocommerce",
      API_ENDPOINT: "https://miraklwoo-api.cedserver.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjViYjQ5NzY2Yzg2NGYxZTU4MDQ4N2E3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjViYzhiZDFhOTQwZGQ2MzljMGM4YzgyIn0.mHA5jH7J5SB1UufffyFm0FUNsXc_ybBdmAJ72-4hXoHtbOd8migo8kmv3LRpC0c3UZsH4yAUugxyJ2USyH7YwIwuLZQ0LMDLWEed8xCzJYpopdyX4h8p0Jew7hZ6AZD0XdBTNk4FS3PinKiit1PEEb6MS9bu3thM--2JDsY5iSnf4Xn-tXOBQO6NbWiCRH4TytsysnNkkcGmuLbLwRyg7SUgphKdn3ut6yMlEEGuE9Bmpueuf8P0jy8qK2WpLqbTVMb-NmuS4pCa7iPcZA3NsI3drQMmSgqIskVkmyivuqG-bIPS4-8o59KxZSqHIQou62V3cliKjzX7nSobhiHPuA",
    },
    mirakl_sfcc_staging: {
      name: "Mirakl-SFCC-Staging",
      API_ENDPOINT: "https://mirakl-sfcc-stag-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjYwZmQ0OWE5Yjk4MzJmNGQyMDYyMGE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjYwZmQ3MDY5NjZjNDljODUzMGZhNGMyIn0.6y-PybzMWRxkb1tqsCn5qh-zeFeWDYHapZwqpWJYxkcnvU1Dtt7nU7M5Sb6HuZ4eBof9JRzoLNsP-Hr3pDiBLN0Ka0i3_J_ren5Un7MSqjL0yyiSKlK83nrhX0If_AkLR-i3WzroMmrjbbvnv7U_X4SS4QtT0Sf_n5HKBLBsSl8XYk5vgTJUSG9wNZbx_vU00ZYqxw5ZXA2WXG5OHlZi-iRAIZQc3T8c1o6_ewhSBztp0SmRSmuX6CM1If0byl-1SPIStIyDuDISgZBepaGxcnVirIgkfAqB0yMH44XEAJebbQjYl8TGFJTmI0-rlgq4fCkRPBxdphi_JVQ1qxpalvegjxwyed0QBB4SBY9xC2B_O0kH94tWPmjVZ2i1Blm1P68JuwgrHeJMRwgUXrJFZJ29Y-VNVofv-mFiZelsopZ5D7vvHqLZyArKDXKZrcv62ckaAGnOhUVoKDnIYTYfOqAIUTiphIBOScXJDIOeaTaS6y41oKep5hleLrR7BhtvRAzUVA6un9SbaLaMavr_EOpvxhIiFUplRoJPW0IrjdEuxLIbiWAIemK7ToM3VAWXsctjk9iG2jMVFAPCQABG9EPZDbU20mweukizag3Y7IBo0zoxWRhsPnJIH-2xceQJQYjHv6KqtDy09i2Wpy8VxyzYHu4va1lvyKtplNh-8zc",
    },
    tiktok_wix_dev: {
      name: "Tiktok-Wix-Development",
      API_ENDPOINT: "https://tiktokwix-dev-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjYxM2IyYmEyZjgwN2RjZjMyMGY0YzY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjYxM2I0MDk4N2NjMDYwYTAzMDRiNzkyIn0.v9ER1AgB5ocsQd2Bj5jSWsoeaEyW_N_qdqD7_DMZ3XtvD8zvBh0fIGjRkABvVmKPLNAzzUBrxEVi2xyZtDGr9_f88Cyf_pxf7YMBUKV1RNBbfQIzsVN5JRHeSCJZMYiwMxL0zm6MIAgIOQzv_kozKSFDMBA4Th0B16XtHeUAVpYMkefqsegSD3lmuPB3PMaSi3VvvNcAMMBnXYfosnSMed2XC9WynWjT_44KJKElfUikBCPXZj0RTj-LQ6sghTduXeNKlxqOSAN_JgUa6nYoNRpp6TdrDcQFTO9NFuT-7ZQxYbt7I7OgJOZC76JIvH3CpfwnU39YN2aTwneOWEAgYwaeern61uWdFW_CuXny2a74vCiy0ZWEszyxGHqKs0aYaJZOvxOF9KIF-v_s28djZW-W-uA5Ox7XHb2Cw5XfFkLHgLq8RPSpgpHssRRGXcXY1W9SBRLtF-wNMl-fQW7Sk-7znU_U-T5kSvcGEdzUzIW9xlbJNg8a6iqNHgebfXwS5sYnW829c1SmvquaX9M30kOeYGTIauVIzSnj2L5c-lmUq6NqVYrl18PlFsEYzD-bz83drZ6ayChxzYR1fupkZsVJ3V_bojLS2oKA6rv7pQK63AoRBYQsF4gdPuzq3CJ3JbGJ4GYFsUyVHdse73rzDB_y4vpsnqpf3VGcdS67P90",
    },
    tiktok_wix_stag: {
      name: "Tiktok-Wix-Staging",
      API_ENDPOINT: "https://tiktokwix-stag-api-backend.cifapps.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjYzOWUxM2ExYzZjZWMxOGU2MDBjZmU3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjYzOWVhMjI5MWM5YmJlZWMwMDEyMGUyIn0.cE4XXjrkmwN_gXycAXJQZtUMe97aMo3TOLpDFJ8r2iOZOTeIkwtCtZuuvj4RQwDshbMGhtv6mbzMYDT8Jj3kYf39VF-JD_2_1Tj2rZbwdrvrH9Mk8I1aBH3r34nSNMVc___Onid5qNWW7qvWbvy3pmAWMkcNAObKvdRbw1k3I-oKH2TgWhCgWd7tdPmg-bStbouV4sZ_kcJIlLGTvuns5V_ERcsL71YwRo0zlsjofRMy1XkYDeXYKh9I8l1aL3TADxmnJ6JQdoWeU5_xPCeeqvRRdrTrOtbNL-HMd7FV4zBftmHzQnSAFO2H9M9HYnH0cNJ0SpcRmatfDmMmU0EbZTB4JnFgDbLDs4c6Nh7hcjkCrflhNoXTBqk-peLqDY4gIJ1NvYk-24VaCuLrBz9Kye1FNEeKaQ-wRTPHM5qlKGTwOrFwjj_0drBIbk1aUHLBSQ8qLdIoFeDV5r03EAKNJ-AN7nMIsKAyUSNxSiVUwcm4IDYDGoRLQh6LUcJmfazbe5g49ghd979x8u-1WDVxxY7Lf-Z4w3DR9xEYbh48fjoIWBlQtzhpD43ALOWkU4gKrOCJ1L9B23P-AwLPbBgy_i9wN3r1GiZ1suyIYPq_pIKlrk0ElfHIfJkyYMJopKhTPCIAI_aM9XdJ_TeDrHE7yAx_KLzfuIrcp2GUZUA2saQ",
    },
    tiktok_eu: {
      name: "Tiktok-EU",
      API_ENDPOINT: "https://tiktok-eu-dev-api-backend.cedserver.com/",
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjYzYjc2NzRiMGNiNmVmYTljMDEwMDk3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjYzYjc5OGEwZTY5MzIwYmI2MGQ0NTkyIn0.cVuBdivN05f5kFoen0vy_zbAGWuc6jKxpwjvxxVnAzwZl0MJJXDOZU0GOIzf28qdu7deQM_zY4RM7AKFVROqSvQ2uw4Sx652qtPR6OwC_2W3PGTM8O8zInrcePCP7RHgRxEyk-jlQSeHugI1AIanDsrXr0uBT9589F54im3_z7dVHDb6JFRhypmPd78meNVdtpAL84dkvWu4zjTmSs7yf6YNo9x-F6Puzm0s_UVZ0VQ8KqfzO421wfoNouDa_xg8M2Js-MDZ2vCRr43YAPTR5mXIdjWpQ1Gd8qVp0PBmYez2lGn5P-gCXgVDMumMvm3w3zqUYYSbcpg6Trr32Z4Ntw",
    },
    mirakl_staging : {
      name: "Mirakl-Staging",
      API_ENDPOINT: "https://mirakl-staging-api-backend.cifapps.com/",
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjU1ZjkxNGNjMjQ2ODU3NWNlMDUzYzQ3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjY2NmY5YWVjZmQ2NWZlY2IzMGNhNzUyIn0.ho8WFaWWRsWPBHPT_DJMvzgkfM-5UVsq3yxdtwA8imSrLv6mrw-_VHKeI4Wb7l4apVQDUvlE3QlBbTpQxossbeabDglX-Yoh_YeFvKQ-YckPOJD0jf2B2HNZWE28M-1-xvKRqJGazYnQFpmbXGt_YDgFANugoSwQY-EGPuchcWK1snfL4mDphS7o1fJAipN7VNHcTs_gfAd4SNiZ4z-TQh1Kw43xkx-wSlXGTgk5J6arbvtdH38DWjhFbdujYvAvnX2ujYnGaloFgK4-ExUWtOcEibZK02AXxhpdAuM90C2eoq8sB8RQoFdQ-sxhZRLj_tAEveFQyh1y_kNGdgXfNg",
    },
    mirakl_woo_staging : {
      name: "Mirakl-Woo-Staging",
      API_ENDPOINT: "https://mirakl-woocommerce-staging-api-backend.cifapps.com/",
      Bearer:      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjZhODkxYzJkYjA5N2U3OTRkMDkxNGE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjZhODkxYzJkYjA5N2U3OTRkMDkxNGE4In0.W4AhxYiatWnlTblSL7NDFtmFGzmbqPR4g5QGi9cP5mtXWFEiL1bOJMZERXiHylkWo8SR6QpaLLPp8TafJEBMVUN6MU_-6Xu_hcvkwUJrlWovapndp5bKgt48_-o91j3sfIW6quecCQuBODMti3Wy5I8ssqiD_CgHspvXhzsdTSwQNnKj1tY9VAlj-BnYrDQgF68Ti2tQcd1Vn6LOOrr4YFIFyRPxtlv5Nk8ow97ynJnYt91Dedg6JMpC2XulqAK8kXPqWM_bsDtFN1hJnoxElrYPJbhn1qccldxTKTobgzqDqOJp2TAlZ8UkrEdA7tUn_PeggKNNlcz0o7zfYesXGA8dAYQzoY0zDEftGfCLbF7ByidhSmCCcyG57QFM_X68OfIuBkj31x7Fevdn7wXvkN6OVyGf_47_wJIhK8mQwYrsETRiIolR99kYoaLECVRaQulz_8ixo0f5l1mQGXkf5eq2F77YOEeJ-a1UR2K1TbGbZ4cJuYv8lZU00yFF0YWx9RnyJLYoiryYUjNf8LZiIB_3aD8p7hvBX_dtnl5Ll72wom0d3QHb4Tm3KE_MzyNDN18ma_IVXkrHuYXB0RB31AvGS4t8e1g-Ecee0r6oSH5SP0K9KFkhJwo2qrBoPFFbh_zXcaqb8gHPQwdPYtUB99qZfcP9QbwqESe0eCWR0qo",
    },
    tiktok_zigzag : {
      name: "Tiktok_Zigzag",
      API_ENDPOINT: "https://zigzag-dev-api-backend.cifapps.com/",
      Bearer:    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjZjNTc0NzY3NWYwMWE2MTFkMDMyMDY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOi8vYXBwcy5jZWRjb21tZXJjZS5jb20iLCJ0b2tlbl9pZCI6IjY2YzU3NTI0MDQyNzQ0MTJkZDAxMTJkMiJ9.DBrs51iWjUVJmXqi6YpPpGs6pALVzAgVJMI0ahCt9xqcscig4vOnexewdrgip4pKKRr0CTuXcP9lVqDlf2mXIuhjvjXi7wN6QvwyebiZaQ6QeSSA0UeXh_u-ZtE-vUxJEBtaCQevZQZGVAu8L6SoEQRIHB8yFqLOl_bU-7is3jpOCN0Mdtz8dwDAd8yiHEcoVeDkBxDzFtk4g59LT3WH8bs_h7A9PcG6RRXQQziGbji3bptRUj_MKUff9wEGYpOqRytFAeHkUX0Xl_IA00eoHTyaCWVPkkAHZEX9X-tywOggoIrEcbV_igEbz0qqJegCVD-ZEBYvVxXC23a7iBhWYw",
    },
    Multichannel_ced : {
      name: "Multichannel-ced",
      API_ENDPOINT: "https://multichannel.cifapps.com/",
      Bearer:    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjY1MGFiMTJkZjZlMjBjZWJmMDg1OGM3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjY1NDRkMjRiZjIxMzM1OGRhMDRjYWEyIn0.WJF84SGxvPH-IgJ-xjXIBIetxGi_gjT92fGuSiDrMtKlhS_i73jRBBOfpwlLx_5iVh07PAIxDJNdOoRi3o7NegCC2lwB-boF2D25ogpBqq4AOg1ZPE4UWoio9py2U8T2S6aHsD_r18VGWTVTxwPjAaJIPWdjQ5EIhoZ0OULCn6CjeZVby8UOjUWiRdGfPDiXXdyelZn-kLk5yEhcL5JvPt-4bF8cJNTafSzVqmwUG_wYXX4haCV3DSheLbVsfmlkB61boVXQkUKZxSfqk6DIYMPFZDSJjijPAO4sqyI9ZBtN1P2Wkku4yYZw8uKWxzc0BhDpZ4Y6SutM53nfszQE3dlURyWCR_hJjU4mhUlP5h-fXTbaKfTHG9wLsW_oZHDbtKrypH1tcBsQj3ccG84gZRgVgQ91EUGBP_WBdTHsBMZI2SBIbcurC8PiegWSod47-GwfWUYsKtaGcAQB7T2xRpCIKPzBeOzi4KJaFLjcP2xpjI6AETJr1-FA02NQOrsmJfdA852NuUC4KqHfydM4UHWIbxh60tTRR6SBlp-R738URRDTAymH_cm_KiY7P64yUC5MfLAPFNLZYEsNb6rH0C2ONlHJDkgM9KTQvklAtpxI9Bxr2qh0jjD60ZluinRM7COiKOWr-iQTS7sL4o-qOemPxrgjof2kwxFCokLPOyI",
    },
  },
};
/* Bearer on the last line is App token for server */
